<script>
export default {
  name: "AppPdiMapPopup"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppText } from "@/components/ui"

defineProps({ data: Object })
</script>

<template>
  <AppFlex direction="col" gap="1">
    <AppText weight="bold">{{ data?.code }} - {{ data?.name }}</AppText>
    <AppText v-if="data?.squareType && data?.operationalType">
      {{ data?.squareType }} | {{ data?.operationalType }}
    </AppText>
    <AppText class="mt-1">{{ data?.zone }}</AppText>
    <AppText>{{ data?.address }}</AppText>
  </AppFlex>
</template>
