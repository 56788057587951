<script>
export default {
  name: "AppLayoutAlert"
}
</script>

<script setup>
import { AppAlert } from "@/components/ui"
</script>

<template>
  <div>
    <AppAlert
      variant="info"
      :uppercase="false"
      :allow-to-close="true"
      :text="'Esta página es solo una maquetación. Pronto se ofrecerá una mejor experiencia de usuario.'"
    />
  </div>
</template>
