<script>
export default {
  name: "AppItineraryWrapper"
}
</script>

<script setup>
import { ref } from "vue"

import { AppStopsWrapper } from "./stops"

import { AppFlex } from "@/components/layouts"
import { AppBadge, AppButtonCollapse, AppText } from "@/components/ui"
import { tc } from "@/lib/services"

const props = defineProps({
  itinerary: Object,
  isFirst: Boolean,
  stops: Object,
  isLast: Boolean
})

const dateIsVisible = ref(false)

const item = ref({
  first: {
    badge: "primary",
    badgeText: tc("General.Origin"),
    text: tc("General.Exit"),
    value: props?.itinerary?.ExitHourPlanned
  },
  last: {
    badge: "warning",
    badgeText: tc("General.Destiny"),
    text: tc("General.Arrival"),
    value: props?.itinerary?.ArrivalHourPlanned
  }
}[props?.isFirst ? "first" : props?.isLast ? "last" : "first"])
</script>

<template>
  <div>
    <AppFlex justify="between">
      <AppFlex gap="2" xsdir="col">
        <!-- Badge -->
        <div>
          <AppBadge
            :type="item.badge"
            :text="item.badgeText"
            shape="pill"
            class="pt-2"
          />
        </div>

        <!-- PDI Name -->
        <AppText weight="bold" class="mt-1" size="6">
          {{ itinerary?.Name }}
        </AppText>
      </AppFlex>

      <!-- Collapse Btn -->
      <AppButtonCollapse
        :visible="dateIsVisible"
        @updateVisibility="dateIsVisible = !dateIsVisible"
      />
    </AppFlex>

    <!-- Time -->
    <AppFlex
      v-if="dateIsVisible"
      direction="col"
      gap="2"
      class="p-3 mt-3 rounded bg-soft-blue"
    >
      <!-- Text -->
      <AppText weight="bold" size="6">
        {{ item.text }}
      </AppText>

      <!-- Value -->
      <AppText size="6">
        {{ $t('Admin.RouteDetails.EstimatedTime', { value: item?.value }) }}
      </AppText>
    </AppFlex>

    <!-- Stops -->
    <AppStopsWrapper
      v-if="stops?.length > 0 && isFirst"
      class="mt-3"
      :stops="stops"
    />
  </div>
</template>
