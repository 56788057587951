import { APP_ENTITY } from "../entity-types.constants"

const API_FIRST_VERSION = "/v1"
export const BASE_URL = `${API_FIRST_VERSION}/${APP_ENTITY}`

export const ENDPOINTS = {
  // ----------------------- Auth Endpoints --------------------------
  AUTH: {
    LOGIN: `${BASE_URL}/login`,
    LOGOUT: `${BASE_URL}/logout`,
    VERIFY_TOKEN: `${BASE_URL}/check-token`,
    PROFILE_CHANGE_PASSWORD: `${BASE_URL}/profile/password`
  },

  // ----------------------- Admin Endpoints -------------------------
  ADMIN: {
    // Base endpoints
    BASE: {
      // POIS
      POIS: `${BASE_URL}/poi`,

      // Routes
      ROUTES_MAP: `${BASE_URL}/route/map`,
      ROUTES_HISTORY: `${BASE_URL}/route/history`,
      MAP_POIS: `${BASE_URL}/poi/map`,
      ROUTE_DETAILS: `${BASE_URL}/route`,
      ROUTEPOI_LOADSHEET: `${BASE_URL}/route/:ROUTEID/loadsheet/:ROUTEPOIID`,
      SOLVE_ALL_ROUTE_ALERTS: `${BASE_URL}/alert/solved/route`,

      // Settings
      SETTINGS: `${BASE_URL}/setting`,

      // Alerts
      ALERTS: `${BASE_URL}/alert/search`,
      UNSOLVED_ALERTS: `${BASE_URL}/alert/unsolved/search`,

      // Users
      USERS: {
        ADMINS: `${BASE_URL}/user/admin`,
        MANAGERS: `${BASE_URL}/user/manager`,
        OPERATORS: `${BASE_URL}/user/operator`,
        PROVIDERS: `${BASE_URL}/user/provider`,
        DRIVERS: `${BASE_URL}/user/driver`
      },

      // Profile
      PROFILE: {
        GENERAL: `${BASE_URL}/profile`,
        UPDATE: `${BASE_URL}/profile/basic`
      }
    },

    // Selectors
    ALL: {
      // Pois
      OPERATIVE_TYPES: `${BASE_URL}/poi/operative/type`,
      POIS_CODES: `${BASE_URL}/poi/code`,
      POIS_DIVISIONS: `${BASE_URL}/poi/division/list`,
      POIS_PLATTFORMS: `${BASE_URL}/poi/platform/list`,
      PROVINCES: `${BASE_URL}/poi/province`,
      TIMEZONES: `${BASE_URL}/poi/operative/timezone`,

      // Routes
      ROUTES_MASTER_CODES: `${BASE_URL}/route/master/code`,
      ROUTES_STATUSES: `${BASE_URL}/route/status`,
      ROUTES_TYPES: `${BASE_URL}/route/type`,
      DRIVERS: `${BASE_URL}/user/driver/list`,
      PROVIDERS: `${BASE_URL}/provider/list`,
      DIVISIONS: `${BASE_URL}/poi/division/list`,
      PLATFORM: `${BASE_URL}/poi/platform/list`,
      POIS: `${BASE_URL}/poi/list`,
      VEHICLE_TYPES: `${BASE_URL}/vehicle/type`,
      VEHICLE_CLASSES: `${BASE_URL}/vehicle/class`,

      // Alerts
      ALERT_TYPES: `${BASE_URL}/alert/type`,
      MASTER_CODES: `${BASE_URL}/route/master/code`,
      ZONES: `${BASE_URL}/zone`
    },

    // Extra Alert Endpoints
    EXPORT_ALERTS: `${BASE_URL}/alert/export`,
    SOLVE_ALERT: `${BASE_URL}/alert/solved`,
    SOLVE_ALL_ALERTS:  `${BASE_URL}/alert/solved/all`,
    UNRESOLVED_ALERTS_COUNT: `${BASE_URL}/alert/unsolved/count`,

    // Extra admin routes endpoints
    EXPORT_HISTORY_ROUTES: `${BASE_URL}/route/history/export`
  },

  // ----------------------- Provider Entpoints ----------------------
  PROVIDER: {
    // Base endpoints
    BASE: {
      // Routes
      CONTRACTED_ROUTES: `${BASE_URL}/route/master`,
      DAILY_ROUTES: `${BASE_URL}/route/daily`,

      // Profile
      PROFILE_GENERAL:  `${BASE_URL}/profile/general`,
      PROFILE_ROUTES_CONFIG:  `${BASE_URL}/profile/routesconfig`,
      PROFILE_CHANGE_PASSWORD:  `${BASE_URL}/profile/password`,

      // Drivers, Trailers and Vehicles
      DRIVERS: `${BASE_URL}/driver`,
      TRAILERS: `${BASE_URL}/trailer`,
      VEHICLES: `${BASE_URL}/vehicle`,

      // Retainers
      RETAINERS: `${BASE_URL}/route/retainer`
    },

    // Selectors
    ALL: {
      // Codes
      MASTER_ROUTE_CODES: `${BASE_URL}/route/master/code`,
      ROUTE_TYPES: `${BASE_URL}/route/type`,

      // Vehicle
      VEHICLE:  `${BASE_URL}/vehicle/list`,
      VEHICLE_TYPE:  `${BASE_URL}/vehicle/type`,
      VEHICLE_CLASS: `${BASE_URL}/vehicle/class`,

      // Trailers
      DOOR_TYPES: `${BASE_URL}/trailer/doorType`,
      TRAILERS: `${BASE_URL}/trailer/list`,

      // Drivers
      DRIVERS: `${BASE_URL}/driver/list`,
      AVAILABLE_DRIVERS: `${BASE_URL}/driver/list-availability`,

      // Pois
      POIS: `${BASE_URL}/poi/list`
    }
  }
}

Object.freeze(API_FIRST_VERSION)
Object.freeze(BASE_URL)
Object.freeze(ENDPOINTS)